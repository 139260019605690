// 招标采购批次记录
import request from '@/utils/request'

// 查询招采批次记录列表
export function recordList(params) {
  return request({
    url: '/dkm/purchaseBatch/recordList',
    method: 'post',
    params: params
  })
}


// 查询招采批次记录列表
export function recordInfo(recordId) {
  return request({
    url: '/dkm/purchaseBatch/recordInfo/' + recordId,
    method: 'get',
  })
}

// 新增/修改招采批次记录明细
export function editRecord(data) {
  return request({
    url: '/dkm/purchaseBatch/editRecord',
    method: 'post',
    data: data
  })
}

// 删除招采批次记录
export function delRecord(recordId) {
  return request({
    url: '/dkm/purchaseBatch/delRecord/' + recordId,
    method: 'post'
  })
}


// 查询招采批次药品列表
export function recordDrugList(params) {
  return request({
    url: '/dkm/purchaseBatch/recordDrugList',
    method: 'post',
    params: params
  })
}


// 查询招采批次记录药品明细
export function recordDrugInfo(drugId) {
  return request({
    url: '/dkm/purchaseBatch/recordDrugInfo/' + drugId,
    method: 'get',
  })
}

// 新增/修改编辑招采批次记录的药品
export function editRecordDrug(data) {
  return request({
    url: '/dkm/purchaseBatch/editRecordDrug',
    method: 'post',
    data: data
  })
}

// 删除招采批次记录
export function delRecordDrug(drugId) {
  return request({
    url: '/dkm/purchaseBatch/delRecordDrug/' + drugId,
    method: 'post'
  })
}

// 获取全部招采批次树结构
export function recordDrugTree(params) {
  return request({
    url: '/dkm/purchaseBatch/recordDrugTree',
    method: 'post',
    params: params
  })
}


// 根据招采批次药品id获取关联的系统包装材料药品明细
export function getDrugDetailList(params) {
  return request({
    url: '/dkm/purchaseBatch/getDrugDetailList',
    method: 'post',
    params: params
  })
}


// 批量新增药品id关联的系统包装材料药品明细
export function addDrugDetailList(params) {
  return request({
    url: '/dkm/purchaseBatch/addDrugDetailList',
    method: 'post',
    data: params
  })
}


// 删除药品id关联的系统包装材料药品明细
export function delDrugDetail(drugDetailId) {
  return request({
    url: '/dkm/purchaseBatch/delDrugDetail/' + drugDetailId,
    method: 'post'
  })
}


// 更新药品id关联的系统包装材料药品明细
export function updateDrugDetail(data) {
  return request({
    url: '/dkm/purchaseBatch/updateDrugDetail',
    method: 'post',
    data: data
  })
}


// 批次药品树结构(根据批次号和品种名称去重)
export function simpleRecordDrugTree(recordId) {
  return request({
    url: '/dkm/purchaseBatch/simpleRecordDrugTree/' + recordId,
    method: 'get',
  })
}


// 得到备选列表
export function getAlternativeList(params) {
  return request({
    url: '/dkm/purchaseBatch/getAlternativeList',
    method: 'post',
    data: params
  })
}

// 查询制剂列表
export function selectPpList(params) {
  return request({
    url: '/dkm/purchaseBatch/selectPpList',
    method: 'post',
    params: params
  })
}



// 获取集采剂型列表
export function getPurchaseBatchFormList() {
  return request({
    url: '/dkm/purchaseBatch/getPurchaseBatchFormList',
    method: 'get',
  })
}

// 编辑条件
export function editCondition(data) {
  return request({
    url: '/dkm/purchaseBatch/editCondition',
    method: 'post',
    data: data
  })
}

// 添加条件
export function addCondition(data) {
  return request({
    url: '/dkm/purchaseBatch/addCondition',
    method: 'post',
    data: data
  })
}


// 查询条件条件
export function conditionList(data) {
  return request({
    url: '/dkm/purchaseBatch/conditionList',
    method: 'post',
    data: data
  })
}


//条件详细信息
export function conditionInfo(conditionId) {
  return request({
    url: '/dkm/purchaseBatch/conditionInfo/' + conditionId,
    method: 'get',
  })
}

//删除条件
export function delCondition(conditionId) {
  return request({
    url: '/dkm/purchaseBatch/delCondition/' + conditionId,
    method: 'post',
  })
}


// 根据条件刷新关联的制剂数据
export function refreshRefPpByCondition(data) {
  return request({
    url: '/dkm/purchaseBatch/refreshRefPpByCondition',
    method: 'post',
    data: data
  })
}


